export const en = {
	'snackeet_metrics.counters.title': 'Graphs',
	'snackeet_metrics.counters._unique_users_count': 'Unique users',
	'snackeet_metrics.counters._landed_count': 'Opened',
	'snackeet_metrics.counters._completed_count': 'Completed',

	'snackeet_metrics.counters.newUsers': 'Users',
	'snackeet_metrics.counters.views': 'Impressions',
	'snackeet_metrics.counters.completed': 'Completed',
	'snackeet_metrics.counters.clicks': 'Clicks Total',
	'snackeet_metrics.counters.leads': 'Leads',
	'snackeet_metrics.counters.answers': 'Interactions',
	'snackeet_metrics.counters.shared': 'Shares',
	'snackeet_metrics.counters.installed': 'Installed',
	'snackeet_metrics.counters.opened': 'Opened',

	'snackeet_metrics.newUsers_count': `{count} { count, plural, =1 { New User } other { New Users }}`,
	'snackeet_metrics.completed_count': `{count} Completed`,
	'snackeet_metrics.views_count': `{count} { count, plural, =1 { Impression } other { Impressions }}`,
	'snackeet_metrics.clicks_count': `{count} { count, plural, =1 { Click } other { Clicks }}`,
	'snackeet_metrics.leads_count': `{count} { count, plural, =1 { Lead } other { Leads }}`,
	'snackeet_metrics.answers_count': `{count} { count, plural, =1 { Answer } other { Answers }}`,
	'snackeet_metrics.shared_count': `{count} { count, plural, =1 { Share } other { Shares }}`,

	'snackeet_metrics.newUsers_description': 'Number of new users',
	'snackeet_metrics.views_description': 'Number of times the Story has been opened',
	'snackeet_metrics.completed_description': `Number of users who reached the last page`,
	'snackeet_metrics.clicks_description': `Number of clicks that redirect to another site or open the popup`,
	'snackeet_metrics.total_clicks_description':
		'<b>Total clicks: </b> Number of clicks of all clickable elements.<br></br><b>Click Through:</b> Number of clicks that open a link.<br></br><b>CTR:</b> Click through rate, ratio between Number of clicks through and Impressions.',
	'snackeet_metrics.leads_description': `Number of forms submitted that include at least 'mail' or the 'phone number'`,
	'snackeet_metrics.answers_description': `Number of responses from interactions, e.g., survey, quiz, game, choices, form...`,
	'snackeet_metrics.shared_description': `Number of times the story has been shared`,

	'stories_graph.category.title': 'Choose a category',

	'snackeet_metrics.platforms.title': 'Devices',
	'snackeet_metrics.platforms.all': 'All devices',
	'snackeet_metrics.platforms.desktop': 'Desktop',
	'snackeet_metrics.platforms.mobile': 'Mobile',
	'snackeet_metrics.platforms.tablet': 'Tablet',
	'snackeet_metrics.platforms.other': 'Other',

	'snackeet_metrics.interval.daily': 'Daily',
	'snackeet_metrics.interval.weekly': 'Weekly',

	'snackeet_metrics.label.percentage': 'Percentage %',
	'snackeet_metrics.label.sortBy': 'Sort by',
	'snackeet_metrics.label.asc': 'Ascending',
	'snackeet_metrics.label.desc': 'Descending',

	'snackeet_metrics.empty.title': `It's empty here`,
	'snackeet_metrics.empty.subtitle':
		'We will display the information as soon as users start interacting with the Story.',

	'snackeet_metrics.link_to_interactions': 'Detailed Users',
	'snackeet_metrics.overview.title': 'Story Overview',
	'snackeet_metrics.funnel.title': 'Unique Users Per Page',

	'snackeet_metrics.responsesPercentages.title.multiple_choice': '{page_number}. Buttons page: {page_name}',
	'snackeet_metrics.responsesPercentages.title.rating': '{page_number}. Rating page: {page_name}',
	'snackeet_metrics.responsesPercentages.title.game': '{page_number}. Wheel page: {page_name}',
	'snackeet_metrics.responsesPercentages.title.form': '{page_number}. Form Select: {page_name} - {field_label}',
	'snackeet_metrics.responsesPercentages.title.ending_page': '{page_number}. Ending Page Rating: {page_name}',
	'snackeet_metrics.responsesPercentages.title': '{page_number}.{page_name}',

	'snackeet_metrics.tooltip.page': 'Page name',
	'snackeet_metrics.tooltip.percentage': 'Percentage',
	'snackeet_metrics.tooltip.users': 'Number of users',
	'snackeet_metrics.tooltip.answers': 'Number of answers',
	'snackeet_metrics.tooltip.answer': 'Answer title',

	'snackeet_metrics.total_users': 'Total users:',
	'snackeet_metrics.average_score': 'Average score:',

	'snackeet_metrics.created_at': 'Created',
	'snackeet_metrics.updated_at': 'Updated',
	'snackeet_metrics.story_name': 'Story Name',

	'snackeet_metrics.lead.email': 'Email',
	'snackeet_metrics.lead.tel': 'Tel',
	'snackeet_metrics.lead.mail': 'Email',
	'snackeet_metrics.lead.firstname': 'First Name',
	'snackeet_metrics.lead.lastname': 'Last Name',
	'snackeet_metrics.lead.company': 'Company',
	'snackeet_metrics.lead.city': 'City',
	'snackeet_metrics.lead.zip': 'Zip',
	'snackeet_metrics.lead.address': 'Address',
	'snackeet_metrics.lead.phone': 'Phone',
	'snackeet_metrics.lead.website': 'Website',

	'snackeet_metrics.lead.table_title': 'Story Leads',
	'snackeet_metrics.forms.table_title': 'Form Submissions',

	'snackeet_metrics.carousel.click_attachment': 'Open Popup',
	'snackeet_metrics.carousel.download': 'Download file',
	'snackeet_metrics.carousel.internal': 'Redirect to page',

	'snackeet_metrics.carousel.mailto': 'Mail to: ',
	'snackeet_metrics.carousel.messageTo': 'Message to: ',
	'snackeet_metrics.carousel.phoneTo': 'Call: ',

	'snackeet_metrics.clicks.tags_title': 'Tag Clicks',
	'snackeet_metrics.clicks.cta_title': 'CTA Blocks Clicks',
	'snackeet_metrics.clicks.share_title': 'Share Block Clicks',
	'snackeet_metrics.clicks.network_title': 'Network Block Clicks',
	'snackeet_metrics.clicks.ending_page': 'Ending Page Buttons Clicks',
	'snackeet_metrics.of_views': 'impressions',

	'snackeet_metrics.sections.title': 'Select Stats:',
	'snackeet_metrics.sections.story': 'Story',
	'snackeet_metrics.sections.pages': 'Page',
	'snackeet_metrics.sections.leads': 'Leads',

	'snackeet_metrics.redirect.next': 'To the next page or by Logic',
	'snackeet_metrics.redirect.back': 'To previous page',
	'snackeet_metrics.redirect.none': 'No Redirection',
	'snackeet_metrics.redirect.to_page': 'To page:',
	'snackeet_metrics.redirect.invalid': 'To an invalid page',

	'snackeet_metrics.media_answer.table_response': 'Answer',
	'snackeet_metrics.media_answer.table_transcription': 'Transcription',
	'snackeet_metrics.media_answer.table_preview': 'Preview',
	'snackeet_metrics.media_answer.type_text': 'Text',
	'snackeet_metrics.media_answer.table_video': 'Video',
	'snackeet_metrics.media_answer.table_image': 'Image',
	'snackeet_metrics.media_answer.table_audio': 'Audio',

	'snackeet_metrics.stories_graph.no_stories': 'No available stories',

	'snackeet_metrics.countries.title': 'Impressions by Country',
	'snackeet_metrics.countries.country': 'Country',

	'snackeet_metrics.referrer.title': 'Referral Sources for Story Link Opens',
	'snackeet_metrics.referrer.description':
		'The following table presents an overview of the origins of traffic to your story, highlighting the social media platforms and websites responsible for directing users to your content',
	'snackeet_metrics.referrer.ref': 'Referrer',
	'snackeet_metrics.referrer.count': 'Count',

	'snackeet_metrics.sources.title': 'Story Sources',
	'snackeet_metrics.sources.source': 'Source',

	'snackeet_metrics.userCodes.title': 'Email Verifications',
	'snackeet_metrics.userCodes.codes_sent': 'Code Sent',
	'snackeet_metrics.userCodes.codes_verified': 'Verified',
	'snackeet_metrics.userCodes.codes_pending': 'Pending',

	'snackeet_metrics.userCodes.email': 'Email',
	'snackeet_metrics.userCodes.status': 'Status',

	'snackeet_metrics.table.pagination.rowsPerPage': 'Rows per page:',

	'snackeet_metrics.domains.title': 'Domains',
	'snackeet_metrics.domains.count': 'Count',
	'snackeet_metrics.domains.domain': 'Domain',
	'snackeet_metrics.domains.statuts': 'Status',

	'snackeet_metrics.blocked_emails.title': 'Disposable Blocked Emails',
	'snackeet_metrics.blocked_emails.count': 'emails',

	'snackeet_metrics.diposable_emails.title':
		'At least <b>{warningEmails}</b> diposable emails dected. Please reach our team by chat or at <link>hello@snackeet.com</link> to request a cleanup.',
	'snackeet_metrics.diposable_emails.contact': 'Request cleanup',

	'snackeet_metrics.buttonList.clicks': 'CTA Buttons Clicks',

	'snackeet_metrics.ctr.title': 'Click through',
	'snackeet_metrics.ctr.rate': 'CTR',

	'snackeet_metrics.top_ctr.title': 'Top performance CTR elements',
	'snackeet_metrics.top_ctr.element': 'Element',
	'snackeet_metrics.top_ctr.clicks': 'Clicks',
	'snackeet_metrics.top_ctr.link': 'link',
	'snackeet_metrics.top_ctr.page': 'Page',
	'snackeet_metrics.top_ctr.cta_btn': 'CTA Button',
	'snackeet_metrics.top_ctr.cta_element': 'CTA Element',
	'snackeet_metrics.top_ctr.share': 'Share Block',
	'snackeet_metrics.top_ctr.network': 'Network Block',
	'snackeet_metrics.top_ctr.carousel': 'Carousel',
	'snackeet_metrics.top_ctr.tag': 'Tag',
	'snackeet_metrics.top_ctr.redirection_button': 'Ending Page Button',

	'snackeet_metrics.top_ctr.share_via': 'Share via',

	'snackeet_metrics.widget.title': 'Floating Widget Stats',
	'snackeet_metrics.widget.installed_tooltip': 'Refers to the number of times the Widget was loaded on the website',
	'snackeet_metrics.widget.opened_tooltip': 'A user clicks on the Widget, indicating interaction and engagement',

	'snackeet_metrics.feedback.count': 'Feedbacks',
	'snackeet_metrics.feedback.title': 'Feedback',
	'snackeet_metrics.feedback.date': 'Created Date',
	'snackeet_metrics.feedback.note': 'Rating',
}
